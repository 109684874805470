import { useEffect, useState } from 'react';
import Script from 'next/script';
import config from 'config/config';
import { useDispatch } from 'src/store/store';
import { loginWithSocial } from 'src/modules/shared/auth/actions';
import { setAlert } from 'src/components/alert/actions';
import { SocialAuthData } from 'src/modules/shared/modal/actions';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { facebookSignupClick, initFacebookSignup } from 'src/utils/facebook';
import Button from 'src/components/buttons/button';
import { Container } from 'src/modules/shared/auth/components/sharedStyles';
type Props = {
  isFunctionalityCookiesEnabled: boolean;
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
  onLoginSuccess: () => void;
};
export default function FacebookLoginButton({
  isFunctionalityCookiesEnabled,
  isCommunitySignup,
  redirectPage,
  onLoginSuccess
}: Props) {
  const dispatch = useDispatch();
  const [fbReady, setFbReady] = useState(false);
  useEffect(() => {
    if (isFunctionalityCookiesEnabled) {
      initFacebookSignup(() => setFbReady(true));
    }
  }, [isFunctionalityCookiesEnabled]);
  const content = <>
      <img alt="Facebook" height="14" src={`${config.cdn.static}img/facebook-logo.svg`} width="14" />
      <span>{i18n.t('Continue with Facebook')}</span>
    </>;
  return <>
      {isFunctionalityCookiesEnabled && <Script src="https://connect.facebook.net/en_US/sdk.js" />}
      <Container data-sentry-element="Container" data-sentry-source-file="facebookLoginButton.tsx">
        <Button disabled={!fbReady || !isFunctionalityCookiesEnabled} text={content} type="secondary" onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickType: 'join-facebook'
        });
        facebookSignupClick((authData?: SocialAuthData) => {
          if (!authData) {
            // TECH-279 Expired or invalid access tokens.
            // The user has to click the button again, we can't call FB.login() manually (popup window will be blocked by most browsers)
            dispatch(setAlert(i18n.t('There was a problem, please try the Facebook button again')));
          } else {
            dispatch(loginWithSocial({
              authData,
              socialType: 'Facebook',
              onLoginSuccess,
              isCommunitySignup,
              redirectPage
            }));
          }
        });
      }} data-sentry-element="Button" data-sentry-source-file="facebookLoginButton.tsx" />
      </Container>
    </>;
}