// @ts-strict-ignore
import { useEffect, useRef } from 'react';
import { useDispatch } from 'src/store/store';
import config from 'config/config';
import { loginWithSocial } from 'src/modules/shared/auth/actions';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import Button from 'src/components/buttons/button';
import { Container } from 'src/modules/shared/auth/components/sharedStyles';
type Props = {
  isFunctionalityCookiesEnabled: boolean;
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
  onLoginSuccess: () => void;
};
export default function GoogleLoginButton({
  isFunctionalityCookiesEnabled,
  isCommunitySignup,
  redirectPage,
  onLoginSuccess
}: Props) {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  // Re-render button when component re-renders because the Google library doesn't run again
  useEffect(() => {
    const handleCredentialResponse = (response: {
      credential: string;
    }) => {
      // Submit response.credential (JWT token) to session/oauth endpoint on successful Google login
      dispatch(loginWithSocial({
        authData: {
          googleToken: response?.credential
        },
        socialType: 'Google',
        onLoginSuccess,
        isCommunitySignup,
        redirectPage
      }));
    };
    const initButton = () => {
      if (buttonRef.current) {
        window.google.accounts.id.initialize({
          client_id: config.google.clientId,
          callback: handleCredentialResponse
        });
        window.google.accounts.id.renderButton(buttonRef.current, {
          logo_alignment: 'center',
          size: 'medium',
          text: 'continue_with',
          width: 260,
          // only pixels allowed, max size on mobile
          click_listener: () => trackFootfallEvent(Event.Clicked, {
            clickType: 'join-google'
          })
        });
      }
    };
    let interval;
    if (!window?.google && isFunctionalityCookiesEnabled) {
      interval = setInterval(() => {
        if (window.google) {
          initButton();
          clearInterval(interval);
        }
      }, 100);
    } else if (isFunctionalityCookiesEnabled) {
      initButton();
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [dispatch, isFunctionalityCookiesEnabled, onLoginSuccess, isCommunitySignup, redirectPage]);
  const content = <>
      <img alt="Google" height="14" src={`${config.cdn.static}img/google-logo.svg`} width="14" />
      <span>{i18n.t('Continue with Google')}</span>
    </>;
  return <>
      <Container data-sentry-element="Container" data-sentry-source-file="googleLoginButton.tsx">
        {!isFunctionalityCookiesEnabled ? <Button disabled={true} text={content} type="secondary" /> : <div ref={buttonRef} />}
      </Container>
    </>;
}