// @ts-strict-ignore
import { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import { required, applyValidation } from 'src/utils/validators';
import { passwordErrors } from 'src/constants/validationErrors';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { closeModal, showSignupModal } from 'src/modules/shared/modal/actions';
import { login } from 'src/modules/shared/auth/actions';
import Button from 'src/components/buttons/button';
import ValidatedTextInput from 'src/components/inputs/validatedTextInput';
import Spacer from 'src/components/spacer';
import SocialLoginButtons from 'src/modules/shared/modal/components/login/socialLoginButtons';
import { Close } from 'src/components/modals/modal';
import { titleLarge, textLinkPrimarySmall } from 'styles-js/mixins/typography';
import { LinkButton } from 'styles-js/buttons';
import { CloseButton } from 'styles-js/modals';
type Props = {
  hasCloseButton?: boolean;
  username?: string;
  onLoginSuccess?: () => void;
};
export default function LoginBox({
  hasCloseButton,
  username,
  onLoginSuccess
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [isUsername, setIsUsername] = useState(!!username);
  const validate = values => {
    const schema = {
      email: [required(i18n.t('This field is required.'))],
      password: [required(passwordErrors.required)]
    };
    return applyValidation(schema, values);
  };
  const onSubmit = async ({
    email,
    password
  }) => {
    await dispatch(login({
      password,
      username: email.trim(),
      onLoginSuccess
    }));
  };
  const onSignupClick = async () => {
    await dispatch(closeModal());
    dispatch(showSignupModal({
      onSignupSuccess: onLoginSuccess
    }));
  };
  const onGetHelpClick = () => helpScoutArticle('71-why-am-i-being-asked-to-sign-in-every-time-i-access-healthunlocked');
  const onLoginDifferentAccountClick = () => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'switch-account',
      clickMetadata: {
        section: Section.LoginModal
      }
    });
    setIsUsername(false);
  };
  return <Container data-sentry-element="Container" data-sentry-component="LoginBox" data-sentry-source-file="loginBox.tsx">
      {hasCloseButton && <Close showText={false} />}
      <InnerContainer data-testid="login-box" data-sentry-element="InnerContainer" data-sentry-source-file="loginBox.tsx">
        {isUsername ? <>
              <Title>{i18n.t('Welcome back')} <BoldTitle>{username}</BoldTitle>!</Title>
              <SmallLinkButton onClick={onLoginDifferentAccountClick}>
                {i18n.t('Not you? Log in')}
              </SmallLinkButton>
            </> : <Title>{i18n.t('Log in to HealthUnlocked')}</Title>}
        <Spacer data-sentry-element="Spacer" data-sentry-source-file="loginBox.tsx" />
        <Formik enableReinitialize={true} initialValues={{
        email: isUsername ? username : '',
        password: ''
      }} validate={validate} onSubmit={onSubmit} data-sentry-element="Formik" data-sentry-source-file="loginBox.tsx">
          {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur
        }) => <Form data-testid="login-box-fields">
              <div className={isUsername ? 'hidden-field' : ''}>
                <ValidatedTextInput dataTestId="email-username-input" id="email" label={i18n.t('Email or username:')} meta={{
              touched: (touched['email'] as boolean),
              error: (errors['email'] as string)
            }} placeholder={i18n.t('Your email or username')} type="text" value={values['email']} onBlur={handleBlur} onChange={handleChange} />
              </div>
              <ValidatedTextInput dataTestId="password-input" id="password" label={i18n.t('Password:')} link={<SmallLink href="/reset">{i18n.t('Forgot your password?')}</SmallLink>} meta={{
            touched: (touched['password'] as boolean),
            error: (errors['password'] as string)
          }} placeholder={i18n.t('Your password')} type="password" value={values['password']} onBlur={handleBlur} onChange={handleChange} />
              <Button dataTestId="log-in-button" fullWidth={true} isSubmit={true} text={i18n.t('Log in')} />
            </Form>}
        </Formik>
        <WordWithLine data-sentry-element="WordWithLine" data-sentry-source-file="loginBox.tsx"><span>{i18n.t('OR')}</span></WordWithLine>
        <SocialLoginButtons onLoginSuccess={onLoginSuccess} data-sentry-element="SocialLoginButtons" data-sentry-source-file="loginBox.tsx" />
        <FooterLink onClick={onSignupClick} data-sentry-element="FooterLink" data-sentry-source-file="loginBox.tsx">{i18n.t('Not a member yet? Join today')}</FooterLink>
        <FooterLink onClick={onGetHelpClick} data-sentry-element="FooterLink" data-sentry-source-file="loginBox.tsx">{i18n.t('Learn how to stay logged in')}</FooterLink>
      </InnerContainer>
    </Container>;
}
export const Container = styled.div`
  ${CloseButton} {
    top: 0;
  }
`;
const InnerContainer = styled.div`
  .hidden-field {
    display: none;
  }
`;
const Title = styled.h2`
  ${titleLarge};
  margin-bottom: 0;
`;
const BoldTitle = styled.span`
  font-weight: ${({
  theme
}) => theme.fontWeightBoldTitle};
  overflow-wrap: break-word;
`;
const SmallLinkButton = styled(LinkButton)<{
  onClick: () => void;
}>`
  ${textLinkPrimarySmall};
  margin-top: 8px;
  margin-bottom: 4px;

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    margin-top: 2px;
  }
`;
const SmallLink = styled.a`
  ${textLinkPrimarySmall};
`;
export const WordWithLine = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: ${({
  theme
}) => theme.greyLine};
  line-height: 0.1em;
  margin: 20px 0;
  font-weight: ${({
  theme
}) => theme.fontWeightBold};

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    margin-bottom: 20px;
  }

  span {
    background: ${({
  theme
}) => theme.colorWhite};
    padding:0 10px;
  }
`;
const FooterLink = styled(LinkButton)`
  display: block;
  margin-top: 20px;
`;