// @ts-strict-ignore
import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import TextInput from 'src/components/inputs/textInput';
import Button from 'src/components/buttons/button';
import i18n from 'src/utils/translate';
import { textSmall, textSmallLight } from 'styles-js/mixins/typography';
import { SrOnly } from 'styles-js/mixins/screenReader';
type Props = {
  dataTestId?: string;
  id: string;
  label?: string;
  labelExtra?: string;
  link?: ReactNode;
  meta: {
    touched: boolean;
    error: string;
  };
  placeholder: string;
  type: 'text' | 'password' | 'email';
  value: string;
  onBlur: (e) => void;
  onChange: (e) => void;
};
export default function ValidatedTextInput({
  dataTestId,
  id,
  label,
  labelExtra,
  link,
  meta: {
    touched,
    error
  },
  placeholder,
  type,
  value,
  onBlur,
  onChange
}: Props): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  return <>
      <Label data-sentry-element="Label" data-sentry-source-file="validatedTextInput.tsx">
        {label && <label htmlFor={id}>
            {label} {labelExtra && <span>{labelExtra}</span>}
          </label>}
        {link}
      </Label>
      <Wrapper $type={type} data-sentry-element="Wrapper" data-sentry-source-file="validatedTextInput.tsx">
        <TextInput autoComplete={type === 'password' ? 'off' : 'on'} className={touched && !error ? 'has-success' : ''} dataTestId={dataTestId} id={id} placeholder={placeholder} type={showPassword ? 'text' : type} validationErrors={touched && !!error ? [error] : []} value={value} onBlur={onBlur} onChange={onChange} data-sentry-element="TextInput" data-sentry-source-file="validatedTextInput.tsx" />
        {type === 'password' && <>
            <ShowPasswordButton ariaControls={id} ariaLabel={showPassword ? i18n.t('Hide password') : i18n.t('Show password')} icon={showPassword ? 'eye-off' : 'eye'} size="sm" type="none" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? i18n.t('Hide') : i18n.t('Show')}
            </ShowPasswordButton>
            <SrOnly aria-live="polite">{showPassword ? i18n.t('Your password is shown') : i18n.t('Your password is hidden')}</SrOnly>
          </>}
      </Wrapper>
    </>;
}
const Wrapper = styled.div<{
  $type: string;
}>`
  position: relative;
  ${({
  $type
}) => $type === 'password' && css`
    input {
      padding-right: 76px; /* add space for show password button */
    }
  `}
`;
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  label {
    ${textSmall}
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: ${({
  theme
}) => theme.fontWeightBold};
    span {
      ${textSmall}
      color: ${({
  theme
}) => theme.colorTextLight};
    }
  }
`;
const ShowPasswordButton = styled(Button)`
  ${textSmallLight}
  position: absolute;
  right: 4px;
  top: 10px;
  padding: 4px 8px;
`;